<template>
  <div class="Logs">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item><router-link to="/">运营中心</router-link></el-breadcrumb-item>
				<el-breadcrumb-item><router-link to="/">帮助文档文档</router-link></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="wf mgtop10" style="min-height: 600px;">

			<el-row :gutter="20">
				<el-col :span="5">
					<div class="frame_content">
						<el-button size="small" type="primary" style="margin-bottom: 16px" icon="menu-fold">新增帮助文档类别</el-button>
						<el-menu class="menu-small" :default-active="0" @select="menuSelect" active-text-color="#ffffff">
							<el-menu-item v-for="(item,index) in helpTypeList" :key="index" :index="index+''">{{item.name}}</el-menu-item>
						</el-menu>
					</div>
				</el-col>
				<el-col :span="19">
					<div class="frame_content">
					<div class="wf lineh40 bold font16">帮助文档详情</div>
					<div class="w800 center-block mgtop20">
					<el-form ref="helpForm" :model="helpForm" label-width="120px" class="wf mgtop20" :rules="rules">
						<el-row :gutter="24">
							<el-col :span="24">
								<el-form-item label="帮助文档名称" prop="name">
								  <el-input v-model="helpForm.name" placeholder="请填写帮助文档名称"/>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="所属分类" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
									<el-select v-model="helpForm.classification" :filterOption="filterOption" style="width: 100%;">
									  <el-option v-for="(level,index) in helpClassificationList" :key="index" :value="level.serial" :label="level.name">
										  <span v-for="(line,mi) in level.fullName.split('-').length-2" :key="mi">--</span>{{level.name}}
									  </el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="帮助文档内容" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
								<div class="pdding10 overflow">
                  <VueUEditor @ready="ueditorReady" style="height:600px;"></VueUEditor>
                </div>
								</el-form-item>
							</el-col>

						</el-row>

					</el-form>
					<div class="wf center">
						<el-button type="primary" size="large" @click="publishHelp"><i class="fa fa-save"></i>保存</el-button>
					</div>
					</div>
					</div>
				</el-col>
			</el-row>
		</div>

  </div>
</template>

<script>
import VueUEditor from 'vue-ueditor'
export default {
	name: 'Help',
	components:{
		VueUEditor
	},
	data () {
		return {
      superSerial:"",
			helpTypeList:[],
			helpClassificationList:[],
			helpClassificationCacheList:[],
			helpForm:{},
			rules:{
			  name: [
			    { required: true, message: '请填写帮助文档名称', trigger: 'blur' },
			    { min: 1, max: 20, message: '长度在 2 到 100个字符', trigger: 'blur' }
			  ],
			  classification: [
			    { required: true, message: '请选择所在分类', trigger: 'blur' }
			  ]
			}
		}
	},
	methods:{
		ueditorReady(ueditor){
			let _this=this
			this.ueditor=ueditor;
			ueditor.addListener("contentChange",()=>{
				_this.helpForm.content=ueditor.getContent();
			});
      if(this.helpForm.content!=undefined)
			ueditor.setContent(this.helpForm.content)
		},
		menuSelect(item){
			//获取当前类别的分类
      this.newItem();
			this.curTypeName=this.helpTypeList[parseInt(item)].name;
			this.curTypeSerial=this.helpTypeList[parseInt(item)].serial;
      this.superSerial = this.curTypeSerial;
			this.getClassificationList();
		},
		getHelpTypeCacheList(){//从缓存获取项目类别
			this.$http.material_getHelpTypeList({pageSize:100}, res=>{
			this.helpTypeList = res.data;
				console.log("项目类别已加载完",this.helpTypeList);
				//获取当前类别的分类
				this.curTypeName = this.helpTypeList[0].name;
				this.curTypeSerial = this.helpTypeList[0].serial;
				this.superSerial = this.curTypeSerial;
				this.getClassificationList();
			});
		},
		getClassificationList(){
			this.$http.material_getHelpClassificationList({superSerial:this.superSerial, pageSize:100},res=>{
        this.helpClassificationList=res.data;
        this.total=res.count;
      });
		},
    newItem(){
    	this.helpForm={};
      this.ueditor.setContent("");
    },
		publishHelp(){
      this.$refs['helpForm'].validate((valid) => {
        if (valid) {
          var helpForm = Object.assign({}, this.helpForm);
      		helpForm.cover=this.mcover;
          var claszzes = helpForm.classification.split("-");
          helpForm.classification = claszzes[claszzes.length-1];
          helpForm.updateTime ="";

      		this.$http.material_publishHelp(this,helpForm,(data)=>{
      			if(data!=null){
              this.helpForm.serial = data.serial;
      			  sessionStorage.setItem("help",JSON.stringify(data))
            }
            this.dialogFormVisible  = false;
      		});
      	}
      });
		},
		filterOption(input, option) {
			console.log(input,option.componentOptions.children[option.componentOptions.children.length-1].text)
		    return option.componentOptions.children[option.componentOptions.children.length-1].text.indexOf(input) >= 0
		}
	},
	created() {
		this.getHelpTypeCacheList();
		this.helpForm=JSON.parse(sessionStorage.getItem("help"));
    if(this.helpForm.serial==undefined || this.helpForm.serial == "")
      this.newItem();
		console.log("编辑",this.helpForm)
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-editor div{line-height: 20px;}
	.edui-default .edui-dialog{z-index: 9999 !important;}
	#edui_fixedlayer{z-index: 9999 !important;}
</style>
